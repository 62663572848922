import React, { FC } from 'react';
import { SvgProps } from 'react-native-svg';
import { Image, View, Pressable, ViewStyle, Linking } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';

import CustomText from '@atoms/CustomText';
import { useMyProfile } from '@services/useMyProfile';
import DownArrow from '@assets/svg/DownArrow';
import Search from '@atoms/Search';
import { AvatarProfile } from '@atoms/AvatarProfile';
import Button from '@atoms/Button';
import handleAnalyticsEvent from '@utils/analytics';
import { AnalyticsEvents } from '@constants/analyticsEvents';
import { useStore, authTokenSelector } from '@store/index';
import { Colors } from '@theme';
import { useTranslations } from '@hooks/useTranslation';
import { useUploadingGameLink } from '@hooks/useUploadingGameLink';

import { styles } from '../styles';
import LoginButton from '../LoginButton/LoginButton';
import { NotificationsButton } from '../NotificationButton/NotificationButton';
import { rootProp } from 'app/navigation/types';
import { RootStackNavigator } from 'app/navigation/types';
import GameBuilderFilterLogo from '@assets/svg/GameBuilderFilterLogo';

interface NavbarLinkButtonProp {
  children: React.ReactNode;
  icon?: FC<SvgProps>;
  style?: ViewStyle;
  screen: keyof RootStackNavigator;
  isLogo?: boolean;
}

const NavbarLinkButton = ({ style, children, icon, screen, isLogo }: NavbarLinkButtonProp) => {
  // hooks
  const { push } = useNavigation<rootProp>();
  const route = useRoute();
  // constants
  const isActive = route.name === screen;

  const iconProps = { fill: isActive ? Colors.limeGreen : Colors.white };
  const Icon = icon;

  // functions
  function handleNavigate(): void {
    if (isActive) return;
    push(screen, { fromDetail: undefined });
  }

  // render
  return (
    <Pressable onPress={handleNavigate} style={[styles.linkButton, style]} disabled={isActive}>
      {Icon ? Icon(iconProps) : null}
      {isLogo ? (
        children
      ) : (
        <CustomText
          weight="interSemi"
          size="xmedium"
          customStyle={styles.buttonText}
          numberOfLines={1}
          color={isActive ? 'limeGreen' : undefined}>
          {children}
        </CustomText>
      )}
    </Pressable>
  );
};

interface DesktopTopbarProps {
  handleUserMenuToggle: () => void;
}

const DesktopTopbar = ({ handleUserMenuToggle }: DesktopTopbarProps) => {
  const { data: userProfile } = useMyProfile();
  const authToken = useStore(authTokenSelector);
  const i18n = useTranslations();
  const link = useUploadingGameLink();

  const handleUploadGame = () => {
    handleAnalyticsEvent(AnalyticsEvents.UPLOAD_GAME_BANNER);
    Linking.openURL(link);
  };

  return (
    <>
      <View style={styles.search}>
        <Search />
      </View>

      <NavbarLinkButton style={styles.btnSecondary} screen="Explore" isLogo={false}>
        {i18n.t('desktop_navbar.explore_games')}
      </NavbarLinkButton>

      <Button onPress={handleUploadGame} customStyle={styles.leftGap} customTextStyle={styles.buttonText}>
        {i18n.t('desktop_navbar.upload_game')}
      </Button>
      <NavbarLinkButton style={styles.btnGameBuilder} screen="GameBuilder" isLogo>
        <View style={{ width: '200px', height: '30px' }}>
          <GameBuilderFilterLogo width="100%" height="auto" />
        </View>
      </NavbarLinkButton>
      {!authToken ? (
        <LoginButton />
      ) : (
        <>
          <NotificationsButton />
          <Pressable hitSlop={20} style={styles.userButton} onPress={() => handleUserMenuToggle()}>
            <AvatarProfile
              customContainerStyle={styles.avatar}
              imageUrl={userProfile?.profileImage || ''}
              backgroundUrl={userProfile?.backgroundProfileImage || ''}
              offsetKitten={2}
            />
            <DownArrow />
          </Pressable>
        </>
      )}
    </>
  );
};

export default DesktopTopbar;
