import React, { useRef, useState } from 'react';
import {
  Animated,
  View,
  ViewStyle,
  NativeScrollEvent,
  NativeSyntheticEvent,
  LayoutChangeEvent
} from 'react-native';

import CustomText from '@atoms/CustomText';
import {
  isMobile,
  isWebResponsive,
  SCREEN_WIDTH as HW_SCREEN_WIDTH,
  WINDOW_WIDTH
} from '@constants/platform';
import GameBanner from '@molecules/GameBanner';
import { moderateScale } from '@utils/scaling';
import { AnalyticsEvents } from '@constants/analyticsEvents';
import { useTranslations } from '@hooks/useTranslation';

import { LATERALPLUS, LATERALSMALL } from '../../../theme/spacing';

import Arrows from './Arrows';
import Dots from './Dots';
import { styles } from './styles';
import useFeatureFlag, { gameQuestEventSelector, halloweenEventSelector } from '@store/featureFlag';
import AddBanner from '@atoms/AddBanner';
import { GoogleAdManagerUnitIdEnum } from '@atoms/GoogleAdManagerBanner/constants';
import GoogleAdManagerBannerWithDelay from '@atoms/GoogleAdManagerBanner/GoogleAdManagerBannerWithDelay.web';
import GameQuestBanner from 'app/components/game-questt/GameQuestBanner';
import { AvatarProfile } from '@atoms/AvatarProfile';
import { CommonActions, useNavigation } from '@react-navigation/core';
import { MotiPressable } from 'moti/interactions';
import HalloweenBanner from '@molecules/HalloweenBanner';

const SCREEN_WIDTH = isMobile ? HW_SCREEN_WIDTH : WINDOW_WIDTH;

interface SliderProps {
  ppal?: boolean;
  might?: boolean;
  title?: string;
  showTags?: boolean;
  tagColor?: string;
  tagTextColor?: string;
  banners: Array<any>;
  qtyToSlide?: number;
  bannersGap?: number;
  pagination?: boolean;
  containerStyles?: ViewStyle;
  innerContainerStyles?: ViewStyle;
  // bannerAd?: ImageProps['source']; //  'bannerAd' PropType is defined but prop is never used
  titleStyles?: ViewStyle;
  bannerDimentions?: {
    width: number;
    height: number;
  };
  customSlide?: (item: any) => JSX.Element;
  // sliderWidth?: number; //'sliderWidth' PropType is defined but prop is never used
  arrows?: boolean;
  arrowsStyle?: ViewStyle;
  directSale: boolean;
  googleAdManagerUnitId?: GoogleAdManagerUnitIdEnum;
  sliderType?: 'USER' | 'GAME';
}

const Slider = ({
  ppal,
  title,
  banners,
  showTags,
  tagColor,
  tagTextColor,
  containerStyles,
  innerContainerStyles,
  titleStyles,
  bannersGap = isMobile ? LATERALSMALL : LATERALPLUS,
  qtyToSlide = 1,
  pagination = false,
  might = false,
  customSlide,
  bannerDimentions = {
    width: moderateScale(SCREEN_WIDTH - LATERALSMALL * 2),
    height: moderateScale(SCREEN_WIDTH - 100)
  },
  arrows = false,
  arrowsStyle,
  directSale,
  googleAdManagerUnitId,
  sliderType,
}: SliderProps) => {
  const i18n = useTranslations();
  const scrollX = useRef(new Animated.Value(0)).current;
  let scrollXvalue = 0;
  const [isFirstPage, setIsFirstPage] = useState(true);
  const [isLastPage, setIsLastPage] = useState(false);
  const scrollViewRef = useRef<any>(null);
  const paginationInterval = (+bannerDimentions.width + bannersGap) * qtyToSlide;
  const isGGQ = useFeatureFlag(gameQuestEventSelector);
  const isHalloween = useFeatureFlag(halloweenEventSelector);
  const navigation = useNavigation();

  if (isGGQ && ppal) {
    const existingGGQBanner = banners.find((banner) => banner.isGGQ);

    if (!existingGGQBanner) {
      const randomPosition = Math.floor(Math.random() * banners.length);
      banners.splice(randomPosition, 0, {
        isGGQ: true,
      });
    }
  }

  if (isHalloween && ppal) {
    const existingHalloweenBanner = banners.find((banner) => banner.isHalloween);

    if (!existingHalloweenBanner) {
      const randomPosition = Math.floor(Math.random() * banners.length);
      banners.splice(randomPosition, 0, {
        isHalloween: true,
      });
    }
  }

  const items = banners;

  const [containerWidth, setContainerWidth] = useState(moderateScale(SCREEN_WIDTH - LATERALSMALL * 2));
  const handleLayout = (event: LayoutChangeEvent) => {
    setContainerWidth(event.nativeEvent.layout.width);
  };

  const animatedEvent = Animated.event([{ nativeEvent: { contentOffset: { x: scrollX } } }], {
    useNativeDriver: false
  });

  const updatePaginationState = (scroll: number, contentWidth: number) => {
    scrollXvalue = scroll;
    setIsFirstPage(scroll <= paginationInterval * 0.15);
    setIsLastPage((prevValue) => prevValue && contentWidth - containerWidth <= scroll + 5);
  };

  const handleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    updatePaginationState(
      Math.floor(event.nativeEvent.contentOffset.x),
      Math.floor(event.nativeEvent.contentSize.width)
    );
    animatedEvent(event);
  };

  const scrollTo = (offset: number) => {
    scrollViewRef.current?.scrollToOffset({ offset, animated: true });
  };

  const scrollToNextPage = () => {
    scrollTo(scrollXvalue + containerWidth + bannersGap);
  };

  const scrollToPreviousPage = () => {
    scrollTo(scrollXvalue - containerWidth - bannersGap);
  };

  const titleFontSize = isMobile || isWebResponsive ? 'big' : 'mlarge';
  const gameBannerTitleSize = isMobile || isWebResponsive ? 'medium' : 'lmedium';
  const gamePpalBannerTitleSize = isMobile || isWebResponsive ? 'xbig' : 'xlarge';

  const bannerHasGap = (index: number) => isMobile || index < items.length - 1;

  const getDirectSaleCarouselBanner = (item: any, index: any) => {
    return (
      <View style={[bannerHasGap(index) && { marginRight: bannersGap }, bannerDimentions]}>
        <AddBanner
          analyticsEvent={AnalyticsEvents.AD_CAROUSEL_CLICK}
          bannerDimentions={{
            width: bannerDimentions.width,
            height: bannerDimentions.height
          }}
          creatorId={0} //T0D0
          isDirectSale={item?.advertising.direct_sale}
          items={
            item?.advertising
              ? [
                  {
                    id: 0,
                    type: 'IMAGE',
                    order: null,
                    advertising: {
                      id: item?.advertising.id,
                      name: item?.advertising.name,
                      imageUrl: item?.advertising.imageUrl,
                      siteUrl: item?.advertising.siteUrl,
                      platform: item?.advertising.platform
                    },
                    game: null
                  }
                ]
              : []
          }
        />
      </View>
    );
  };

  const getGoogleAdManagerBanner = (item: any, index: any) => {
    if (googleAdManagerUnitId) {
      return (
        <View
          style={[
            bannerHasGap(index) && {
              // flex: 1,
              marginRight: bannersGap
            },
            bannerDimentions
          ]}>
          <GoogleAdManagerBannerWithDelay
            unitId={googleAdManagerUnitId}
            adErrorPlaceholder={getDirectSaleCarouselBanner(item, index)}
          />
        </View>
      );
    }
    return null;
  };

  const getGameBanner = (item: any, index: number) => (
    <View style={bannerHasGap(index) && { marginRight: bannersGap }}>
      {customSlide ? (
        customSlide(item)
      ) : (
        <GameBanner
          tag={getTags(item, showTags)}
          tagColor={tagColor}
          tagTextColor={tagTextColor}
          item={might ? item : item?.game}
          dimentions={bannerDimentions}
          ppal={ppal}
          title={
            <CustomText
              align="left"
              numberOfLines={2}
              size={ppal ? gamePpalBannerTitleSize : gameBannerTitleSize}
              weight="interSemi">
              {might ? item.name : item?.game?.name}
            </CustomText>
          }
        />
      )}
    </View>
  );

  const getTags = (item: any, value1?: boolean, value2?: boolean) => {
    if (value1) {
      return i18n.t(item?.game?.genre?.translate);
    }
    if (value2) {
      return i18n.t(item.genre.translate);
    }
    return undefined;
  };

  const handleUser = (userName: string) => {
    const navigateAction = CommonActions.navigate({
      name: 'UserProfile',
      params: {
        userName,
      },
      key: `UserProfile-${userName}`,
    });
    navigation.dispatch(navigateAction);
  };

  return (
    <View style={[styles.container, containerStyles]} onLayout={handleLayout}>
      <View style={styles.header}>
        {title && (
          <CustomText customStyle={[styles.title, { ...titleStyles }]} size={titleFontSize} align="left">
            {i18n.t(title)}
          </CustomText>
        )}
        {title && arrows ? (
          <Arrows
            onNext={scrollToNextPage}
            onPrevious={scrollToPreviousPage}
            isFirstPage={isFirstPage}
            isLastPage={isLastPage}
          />
        ) : null}
      </View>
      <Animated.FlatList
        horizontal
        pagingEnabled
        snapToInterval={paginationInterval}
        decelerationRate="fast"
        scrollEventThrottle={16}
        contentContainerStyle={[styles.scrollContainer, { ...innerContainerStyles }]}
        showsHorizontalScrollIndicator={false}
        onScroll={handleScroll}
        ref={scrollViewRef}
        data={items}
        onEndReached={() => setIsLastPage(true)}
        keyExtractor={(item, index) =>
          `${sliderType === 'USER' ? item.id : item?.game?.id}-${index}-${sliderType}`
        }
        renderItem={({ item, index }) => {
          if (sliderType === 'USER') {
            return (
              <MotiPressable
                onPress={() => handleUser(item.userName)}
                style={[styles.userContainer, { padding: moderateScale(10) }]}
                animate={({ hovered }) => {
                  'worklet';

                  return {
                    scale: hovered ? 1.1 : 1,
                    opacity: hovered ? 1 : 0.8,
                  };
                }}>
                <AvatarProfile
                  imageUrl={item.avatar ?? ''}
                  imageUrlPlaceholder={item.profileImagePlaceholder}
                  customContainerStyle={styles.creatorAvatar}
                  backgroundUrl={item.backgroundProfileImage}
                  offsetKitten={5}
                />
                <CustomText numberOfLines={1} size="msmall">
                  {`@${item.userName}`}
                </CustomText>
              </MotiPressable>
            );
          }
          if (!item?.game && item?.advertising && directSale) {
            return getDirectSaleCarouselBanner(item, index);
          }
          if (!item?.game && item?.advertising && !directSale) {
            return getGoogleAdManagerBanner(item, index);
          }

          if (item.isGGQ && !title) {
            return (
              <View style={bannerHasGap(index) && { marginRight: !title ? bannersGap : 0 }}>
                <GameQuestBanner dimensions={bannerDimentions} isPrincipal={ppal} />
              </View>
            );
          }

          if (item.isHalloween && !title) {
            return (
              <View style={bannerHasGap(index) && { marginRight: !title ? bannersGap : 0 }}>
                <HalloweenBanner dimensions={bannerDimentions} isPrincipal={ppal} />
              </View>
            );
          }

          return getGameBanner(item, index);
        }}
      />
      {pagination ? (
        <Dots
          scrollX={scrollX}
          quantity={items.length}
          sliderWidth={containerWidth}
          scrollTo={(index) => scrollTo(index * containerWidth)}
        />
      ) : null}
      {!title && arrows ? (
        <Arrows
          onNext={scrollToNextPage}
          onPrevious={scrollToPreviousPage}
          isFirstPage={isFirstPage}
          isLastPage={isLastPage}
          containerStyle={arrowsStyle ?? styles.bottomArrows}
          isDesktop={!!arrowsStyle}
        />
      ) : null}
    </View>
  );
};

export default Slider;
