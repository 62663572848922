/* eslint-disable consistent-return */
import React, { useState } from 'react';
import { View, Pressable, ActivityIndicator, Linking, Image } from 'react-native';
import { NativeStackNavigationProp, NativeStackScreenProps } from '@react-navigation/native-stack';
import { Portal } from '@gorhom/portal';
import { useNavigation, CommonActions } from '@react-navigation/core';
import { useTranslation } from 'react-i18next';

import MoreIcon from '@assets/svg/MoreIcon';
import Flag from '@assets/svg/Flag';
import ShareIcon from '@assets/svg/ShareIcon';
import CustomText from '@atoms/CustomText';
import { Layout } from '@organisms/Layout';
import { AvatarProfile } from '@atoms/AvatarProfile';
import { UserProfileInfo } from '@atoms/UserProfileInfo';
import Button from '@atoms/Button';
import { UserFollowing } from '@atoms/UserFollowing';
import { LikedGameList } from '@atoms/LikedGameList';
import { CreatorGameList } from '@atoms/CreatorGameList';
import { useGetUserProfile } from '@services/useGetUserProfile';
import { useGetLikedGames } from '@services/useGetLikedGames';
import { useGetGamesByCreator } from '@services/useGetGamesByCreator';
import { isWebResponsive, IS_SMALL_DEVICE } from '@constants/platform';
import { authTokenSelector, useStore } from '@store/index';
import { Colors } from '@theme';
import { useUpdateFollow } from '@services/useUpdateFollow';
import { animateLayout } from '@utils/animation';
import { moderateScale } from '@utils/scaling';
import { RootStackNavigator } from 'app/navigation/types';
import toast from '@utils/toast';
import share from '@utils/shareApp';
import { CustomModal } from '@molecules/CustomModal';

import { buildLink } from '../GamePage/utils';

import { styles } from './styles';

type UserProfileProps = NativeStackScreenProps<RootStackNavigator, 'UserProfile'>;

const UserProfile = ({ route }: UserProfileProps) => {
  const authToken = useStore(authTokenSelector);
  const [showMore, setShowMore] = useState<boolean>(false);
  const [showReport, setShowReport] = useState<boolean>(false);
  const userName = route?.params?.userName;
  const navigation = useNavigation<NativeStackNavigationProp<RootStackNavigator>>();
  const i18n = useTranslation();

  const { data, isLoading: isLoadingUserProfile } = useGetUserProfile(userName);
  const { data: likedGames, isLoading: isLoadingLikedGames } = useGetLikedGames(data?.id, data?.type);
  const { data: creatorGames, isLoading: isLoadingCreatorGames } = useGetGamesByCreator(data?.id, data?.type);
  const updateFollow = useUpdateFollow(authToken || '');

  const handleFollow = () => {
    if (!authToken) return navigation.navigate('Login');
    updateFollow.mutate(data?.id);
  };

  const handleMore = () => {
    animateLayout();
    setShowMore(true);
  };

  const onReport = () => {
    if (!authToken) return navigation.navigate('Login');
    animateLayout();
    setShowMore(false);
    setShowReport(true);
  };

  const toReport = () => {
    Linking.openURL(
      'mailto:hello@gato.us?subject=Report account on GATO&body=Hi there,\n\nYour reporting helps make GATO a better, safer and more welcoming place for everyone.\n\nTo help our team deal with this report effectively, please let us know more about why you reported this profile. This will only be shared with GATO.\n\n## Please type the username you want to report and the reason above this line ##\n\nOur support staff will respond as soon as possible. Thanks for your report.\n\nCheers!\nGATO\n\n'
    );
    setShowReport(false);
  };

  const shareLink = async () => {
    try {
      const link = await buildLink('profile', data?.userName);
      return link;
    } catch (err) {
      toast.danger({ title: i18n.t('error.error_generic'), icon: 'error' });
    }
  };

  const onShare = async () => {
    setShowMore(false);
    const sharedLink = await shareLink();

    // const message = `${i18n.t('profile.share_profile_1')} ${data?.fullName} ${i18n.t(
    //   'profile.share_profile_2'
    // )} ^-^ ${sharedLink}\n\n${i18n.t('profile.share_profile_3')}`;
    try {
      share(sharedLink);
    } catch (error: any) {
      toast.danger({ title: i18n.t('error.error_generic'), icon: 'error' });
    }
  };

  const handlePressEngineGame = (id, slug) => {
    const navigateAction = CommonActions.navigate({
      name: 'GamePage',
      params: {
        gameId: id,
        name: slug,
      },
      key: `GamePage-${id}`,
    });
    navigation.dispatch(navigateAction);
  };

  return (
    <>
      <Layout
        padding
        withScroll
        showScrollIndicator={false}
        tabOffset={IS_SMALL_DEVICE ? 100 : 80}
        desktopFooter>
        {!data || isLoadingUserProfile || isLoadingLikedGames || isLoadingCreatorGames ? (
          // LOTTIE LOADER
          <View style={styles.loading}>
            <ActivityIndicator size="large" color={Colors.greySix} />
          </View>
        ) : (
          <>
            {/* <View style={styles.headerLeft}>
              <GoBackButton />
            </View>
            {!data?.isOwnProfile && (
              <View style={styles.headerRight}>
                <Pressable onPress={handleMore}>
                  <View>
                    <MoreIcon />
                  </View>
                </Pressable>
              </View>
            )} */}

            <View style={styles.headerContainer}>
              <AvatarProfile
                enableAvatarModal
                offsetKitten={5}
                imageUrl={data?.profileImage}
                backgroundUrl={data?.backgroundProfileImage ?? undefined}
              />

              <UserProfileInfo
                fullName={data?.fullName}
                userName={data?.userName}
                userType={data?.type}
                bio={data?.bio}
              />

              <UserFollowing
                userType={data?.type}
                userId={data?.id}
                authToken={authToken}
                likes={likedGames?.length || 0}
                games={creatorGames ? creatorGames[0]?.games?.length : 0}
                followings={data?.following}
                followers={data?.followers}
              />

              {!data?.isOwnProfile ? (
                <View style={styles.profileButtons}>
                  <Pressable
                    onPress={handleFollow}
                    disabled={updateFollow.isLoading}
                    style={[
                      styles.btn,
                      data?.isFollowByYou ? styles.unfollow : undefined,

                      { opacity: updateFollow.isLoading ? 0.8 : 1 },
                    ]}>
                    <CustomText weight="interSemi" color={data?.isFollowByYou ? 'greySix' : 'limeGreen'}>
                      {data?.isFollowByYou ? i18n.t('profile.unfollow') : i18n.t('profile.follow')}
                    </CustomText>
                  </Pressable>

                  {/* <View style={styles.headerRight}> */}
                  {!isWebResponsive && (
                    <Pressable onPress={handleMore}>
                      <View>
                        <MoreIcon />
                      </View>
                    </Pressable>
                  )}
                  {/* </View> */}
                </View>
              ) : (
                <View style={{ marginTop: 30 }} />
              )}
            </View>

            {data?.type === 'PLAYER' && data?.createdGames && data?.createdGames.length && (
              <View style={{ marginBottom: moderateScale(64) }}>
                <CustomText
                  customStyle={{
                    marginBottom: moderateScale(20),
                    fontSize: moderateScale(isWebResponsive ? 16 : 30),
                  }}
                  align="left"
                  weight="interSemi"
                  color="greySix">
                  {i18n.t('profile.games_created_else')}
                </CustomText>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    gap: moderateScale(8),
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  {data?.createdGames.map((game) => (
                    <Pressable
                      onPress={() => handlePressEngineGame(game.id, game.gameSlug)}
                      key={game.id}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        gap: moderateScale(8),
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}>
                      <View
                        style={{
                          padding: moderateScale(16),
                          borderRadius: moderateScale(12),
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-start',
                          backgroundColor: Colors.blackTwo,
                          alignItems: 'center',
                          gap: moderateScale(10),
                          width: isWebResponsive ? '90vw' : '15vw',
                          height: '100px',
                        }}>
                        <Image
                          style={{
                            width: '90px',
                            height: '90px',
                            resizeMode: 'cover',
                            borderRadius: 16,
                            overflow: 'hidden',
                          }}
                          source={{ uri: game.principalImage }}
                        />
                        <View
                          style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'row',
                          }}>
                          <CustomText align="left" size="medium" weight="interSemi">
                            {game.name}
                          </CustomText>
                        </View>
                      </View>
                    </Pressable>
                  ))}
                </View>
              </View>
            )}

            {data?.type === 'PLAYER' ? (
              <LikedGameList isOwnProfile={data?.isOwnProfile} name={data?.userName} games={likedGames} />
            ) : (
              <CreatorGameList
                name={data?.userName}
                isOwnProfile={data?.isOwnProfile}
                games={creatorGames ? creatorGames[0]?.games : []}
              />
            )}

            {showMore && (
              <Pressable onPress={() => setShowMore(false)} style={styles.blurContainer}>
                <View style={styles.moreContainer}>
                  <Pressable onPress={onShare} style={styles.padding}>
                    <View style={styles.row}>
                      <CustomText weight="interSemi" align="left">
                        {i18n.t('profile.share_profile')}
                      </CustomText>
                      <ShareIcon fill={Colors.greySix} />
                    </View>
                  </Pressable>
                  <Pressable onPress={onReport} style={styles.padding}>
                    <View style={styles.row}>
                      <CustomText weight="interSemi" align="left">
                        {i18n.t('profile.report')}
                      </CustomText>
                      <Flag />
                    </View>
                  </Pressable>
                </View>
              </Pressable>
            )}
          </>
        )}
        <View style={styles.footer} />
      </Layout>
      <Portal>
        <CustomModal
          visible={showReport}
          onOverlayPress={() => setShowReport(false)}
          bottomFixed={false}
          customCardStyle={styles.modal}>
          <Pressable onPress={() => setShowReport(false)}>
            <View style={styles.bottomsheet}>
              <View>
                <CustomText size="xbig" weight="interSemi" align="center">
                  {i18n.t('profile.report_account')}
                </CustomText>
                <CustomText
                  customStyle={{ marginTop: moderateScale(20) }}
                  size="xmedium"
                  align="center"
                  color="greySix"
                  weight="interSemi">
                  {i18n.t('comments.report_comment_body')}
                </CustomText>
              </View>
              <View style={styles.button}>
                <Button
                  customTextStyle={{ fontSize: moderateScale(18) }}
                  customStyle={styles.reportBtn}
                  onPress={toReport}>
                  {i18n.t('comments.delete_comment_confirm')}
                </Button>
                <Button
                  secondary
                  customTextStyle={{ fontSize: moderateScale(18) }}
                  customStyle={styles.cancelBtn}
                  onPress={() => setShowReport(false)}>
                  {i18n.t('comments.delete_comment_cancel')}
                </Button>
              </View>
            </View>
          </Pressable>
        </CustomModal>
      </Portal>
    </>
  );
};

export default UserProfile;
